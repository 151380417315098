/* You can add global styles to this file, and also import other style files */

@import '~animate.css/animate.min';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@media screen and (max-width: 991px) {
    .nav-open .main-panel {
        right: 0;
        transform: translate3d(200px, 0, 0);
    }
}

body {
    margin: 0;
}

.p-button.p-button-icon-only {
    width: 2.357rem;
    padding: 0.5rem 0;
    height: 42px;
}

.p-field-checkbox>label,
.p-field-radiobutton>label {
    margin-bottom: 0;
}